<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon color="secondary" left large>mdi-bell</v-icon>
              New Feature: Custom IAO Forms
              <v-chip color="secondary" class="ml-2 preview-chip">Preview</v-chip>
            </v-card-title>
            <v-card-text>
              <p>We're thrilled to preview <strong>Custom IAO Forms</strong> – a game-changer for enterprise IAO management.</p>
              <p>Create bespoke forms with conditional questions to capture precise IAO details. Tailor every aspect to fit your unique operational needs.</p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="info" @click="expressInterest">
                <v-icon left>mdi-email</v-icon>Express Interest
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>
              <v-icon color="secondary" left>mdi-eye</v-icon>
              Forms Preview
            </v-card-title>
            <v-card-text>
              <p>Here's sneak peek into the flexibility of Custom IAO Forms:</p>
              <v-carousel>
                <v-carousel-item v-for="n in 3" :key="n">
                    <v-img :src="`/static/forms-preview-image-${n}.png`" alt="Preview Image"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  export default {
    name: 'FeatureHighlight',

    methods: {
      expressInterest() {
        const email = "unsales@unstruct.ai";
        const subject = "I'm interested in Custom Incident Forms";
        const body = "Hi,\n\nI'm interested in Custom Incident Forms. Please contact me at your earliest convenience.\n\nThanks!";
        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
      },
    },
  };
  </script>

  <style scoped>
  .preview-chip {
    font-size: 12px;
    top: -10px;
  }

  </style>
